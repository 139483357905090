<div>


    <div align="right">
    
      בס"ד
      
    </div>
    
    <div align="center">
      <img src="../assets/Pic3.png">
    </div>
    
    
    <h2 align="center">
    
    
      חברת רב מסר הינה חברה מובילה בתחומה בישראל.
      בהובלתו של ד"ר רוני הורוביץ, החברה פיתחה מערכת מתקדמת לדיוור אלקטרוני ולניהול והפעלת דפי נחיתה
     
      <ul>
        במסגרת הקשר שלי עם חברת רב מסר, דף זה מאפשר לך להתנסות במערכת, בחינם, במהלך חודש ימים. במהלך תקופת ההתנסות, המערכת תאפשר לך לשלוח עד 6000 דיוורים לעד 1000 כתובות של לקוחות ושימוש נרחב במערכת לתכנון ויישום מסכי נחיתה
      </ul>
      <ul>
        !לנוכח השימוש הנרחב ברב מסר בישראל - ממליץ בחום לנסות את המוצר
      </ul>
    
     <ul>
      ד"ר נח בל
     </ul>
     <ul>
     
     </ul> 
     
     <ul>
     
    </ul> 
    
    
    </h2>
    
    
    <div align="center">
      <img src="../assets/logo rav meser.png">
    </div>
    
    <h2 align="right">
    ? למה רב מסר 
    <ul>
    מסרים שנוגעים בקהל היעד - עיצוב וייצור של מסרי דואר אלקטרוני במטרה להניע את הנמענים לפעולה
    </ul>
    <ul>
      מחולל דפי הנחיתה מהיר ופשוט לתפעול! יצירת דפי נחיתה מקצועיים לשפור יחס ההמרה
    </ul>
    <ul>
      כלי חיוני לצורך שיפור וייעול התהליך השיווקי: מחולל טפסים משוכלל, תזמון שליחת מסרים אוטומטיים, בניית תהליכי שיווק אוטומטיים מורכבים ו"כיול" איכות המסרים לשיפור הביצועים העיסקיים
    </ul>
    </h2>
    
    </div>
    
    <p align="center">
    
    </p>
    
    <p align="right">
    
      
      </p>
    <h2>
      <div align="center">
        <p> 
       : להתנסות חינם במערכת, על ידי התחברות ישירה לאתר הרשמי של חברת רב מסר 
         
        </p>
       
    
     
       </div>
    </h2>
    
    
    
    <h3 align="center">
    
    </h3>
    
    
    
    <link type="text/css" rel="stylesheet" href="https://cp.responder.co.il/partners/css/trial.new.new.css" />
    <link type="text/css" rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
    <script type="text/javascript" src="https://cp.responder.co.il/partners/js/trial.new.new.js"></script>
    <!--[if lt IE 10]>
    <script type="text/javascript" src="https://cp.responder.co.il/partners/js/ie9fix.js"></script>
    <![endif]-->
    <div class="partnersFormWrapper">
      <div class="intro">
        <p>מלאו את הפרטים על פי ההנחיות ולחצו על הכפתור "צור חשבון התנסות". המערכת תשלח אליכם מייל לאימות הרשמתכם. לאחר לחיצה על הקישור המיוחד במייל האימות החשבון שלכם ייפתח והחוויה תתחיל!</p>
        <!--<p>שימו לב! בנוסף למייל האימות יישלח אליכם מסר נוסף – הראשון בסדרה לימודית שתתן לכם טיפים מעשיים ורעיונות לשיווק באמצעות דואר אלקטרוני.</p>
        <p>כל השדות הם שדות חובה.</p>-->
      </div>
      <form class="partners-form" action="https://cp.responder.co.il/trial.php" method="post" onsubmit="return responder_validate(this);" id="responder_partner_form">
        <div class="form-element">
          <label class="title">שם פרטי:</label>
          <div class="item-cont">
            <input type="text" class="large" name="first" placeholder="שם פרטי"  maxlength="20"/>
            <i class="fa fa-user"></i> </div>
        </div>
        <div class="form-element">
          <label class="title">שם משפחה:</label>
          <div class="item-cont">
            <input type="text" class="large" name="last" placeholder="שם משפחה"  maxlength="20"/>
            <i class="fa fa-user-o"></i> </div>
        </div>
        <div class="form-element">
          <label class="title">מספר טלפון:</label>
          <div class="item-cont">
            <input type="text" class="large" name="phone" placeholder="מספר טלפון"  maxlength="20"/>
            <i class="fa fa-phone"></i></div>
        </div>
        <div class="form-element">
          <label class="title">כתובת דוא"ל</label>
          <div class="item-cont">
            <input type="text" class="large" name="email" placeholder="כתובת דואר אלקטרוני אליה תשלח הודעת אימות"  maxlength="100"/>
            <i class="fa fa-envelope"></i></div>
        </div>
        <div class="form-element">
          <label class="title">שם משתמש:</label>
          <div class="item-cont">
            <input type="text" class="large" name="username" placeholder="שם משתמש: אותיות לטיניות, ספרות וקו תחתון בלבד"  maxlength="20"/>
            <i class="fa fa-user-circle"></i></div>
        </div>
        <div class="form-element">
          <label class="title">סיסמה:</label>
          <div class="item-cont">
            <input type="password" class="large" name="password"  placeholder="לפחות 6 תווים, לפחות בינונית בחוזקה" maxlength="20" onkeyup="responder_change_password(this);" />
            <i class="fa fa-lock"></i></div>
          <div id="responder_password_notification"> <img id="responder_password_img" src="https://cp.responder.co.il/partners/images/cancel_x.new.png" alt="X" /> <span id="responder_password_text">הסיסמה חלשה מידי</span> </div>
        </div>
        <div class="form-element">
          <label class="title">אישור סיסמה:</label>
          <div class="item-cont">
            <input class="large" type="password" name="password2" value="" placeholder="אישור סיסמה" />
            <i class="fa fa-lock"></i></div>
        </div>
        <div class="submit">
          <input type="hidden" name="pid" value="1591" />
          <input type="hidden" name="encoding" value="" />
          <input type="submit" value="צור חשבון התנסות" />
        </div>
      </form>
    </div>