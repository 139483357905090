import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RavmeserComponent } from './pages/ravmeser/ravmeser.component';
import { ToolsComponent } from './pages/tools/tools.component';
import { Tool2Component } from './pages/tool2/tool2.component';

const routes: Routes = [
    {
        path: 'tools/ravmeser',
        component: RavmeserComponent
    },
    {
        path: 'tools',
        component: ToolsComponent
    },
    {
        path: '',
        component: Tool2Component
    },
    {
        path: 'tool2',
        component: Tool2Component
    }
    
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
