import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { RavmeserComponent } from './pages/ravmeser/ravmeser.component';

import { AppRoutingModule } from "./app-routing.module";
import { ToolsComponent } from './pages/tools/tools.component';
import { Tool2Component } from './pages/tool2/tool2.component';

import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { ClickfunnelsComponent } from './pages/clickfunnels/clickfunnels.component';

@NgModule({
  declarations: [
    AppComponent,
    RavmeserComponent,
    ToolsComponent,
    Tool2Component,
    ClickfunnelsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
