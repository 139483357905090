<h1>Main menu</h1>
<nav>
  <a routerLink="tools/ravmeser" routerLinkActive="active">Rav Meser</a>
  <br>
  <a routerLink="tools" routerLinkActive="active">Tools</a>
<br>
<a routerLink="tool2" routerLinkActive="active">Go to Tool2</a>
<br>
<a routerLink="clickfunnels" routerLinkActive="active">Go to Clickfunnels</a>


</nav>
