import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ravmeser',
  templateUrl: './ravmeser.component.html',
  styleUrls: ['./ravmeser.component.scss']
})
export class RavmeserComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
